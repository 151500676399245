import { keys } from "../utils/keys";
import { baseRequest } from "../utils/axios";

// get popular categories for homepage
export const getBranchDetails = ({ branch, isThunkCall = false }) => {
  let query = `query {
    branch_info(url: "${branch}") {
      branch_id store_id name status type address1 address2 address3 address4 postcode latitude telephone longitude distance hyperlink description opening_times email is_hub is_NDC opening_mon_fri closing_mon_fri open_saturday opening_sat closing_sat open_sunday opening_sun closing_sun type_id additional_info
      image { url name }
      holiday_info { date, description } }
    }`;

  if (isThunkCall) {
    query = `query {
      branch_info(url: "${branch}") {
        branch_id store_id name type address1 address2 address3 address4 area distance postcode latitude telephone longitude hyperlink email type_id
      }
    }`;
  }
  return baseRequest(keys.general.graphqlUrl, query);
};

// get branches lists
export const getBranchesList = ({ lat, lng, limit = 5 }) => {
  const apiUrl =
    typeof window === "undefined"
      ? keys.general.graphqlUrl
      : keys.general.backendGraphqlUrl;

  return baseRequest(
    apiUrl,
    `query {
      nearest_branches(lat:${lat} , long:${lng}, pageSize: ${limit}) {
        branch_id store_id type name area email is_hub is_NDC address1 address2 address3 address4 postcode latitude longitude telephone hyperlink distance description opening_sat closing_sat open_sunday opening_sun closing_sun open_saturday closing_mon_fri opening_mon_fri
        holiday_info { date }
      }
    }`,
  );
};

// get branches lists
export const getAllBranches = () => {
  return baseRequest(
    keys.general.graphqlUrl,
    `query {
      branch_list {
        branch_id name type address1 address2 address3 address4 postcode latitude longitude hyperlink telephone
      }
    }`,
  );
};

// get branches list timings
export const getDefaultTimings = () => {
  return baseRequest(
    keys.general.graphqlUrl,
    `query {
      branch_default_timing { opening_mon_fri closing_mon_fri opening_sat closing_sat opening_sun closing_sun open_saturday open_sunday }
    }`,
  );
};
